import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const faqData = [
  {
    title: "General",
    questions: [
      {
        q: "What is this?",
        a: `
          This is an online maker for requesting 3d printed site models.
          The maker uses the latest street map data to generate 3d printable models in the rough volume of the requested buildings.
          These models are then handed off to our designers who will work with you to achieve the desired level of detail.
        `,
      },
      {
        q: "How are your models generated?",
        a: `
          We use a computer assisted design process which our initial models are created with the latest street data
          and then passed off to our designers to ensure their printability or add additional details.
        `,
      },
      {
        q: "What are the models mades of?",
        a: `
          Our models are created with a biodegradable corn plastic.
          The plastics is strong enough to last for 100s of years indoors while being compostable in a matter of months.
        `,
      },
      {
        q: "How much does a model cost?",
        a: `
          The price depends on the number of models selected, desired scale, and final detail level.
        `,
      },
      {
        q: "Do you offer bulk order discounts?",
        a: `
          Yes we will offer discounts on bulk orders.
          Bulk orders allow us to streamline our process and we believe in reflecting those efficiency gains in our price.
        `,
      },
      {
        q: "How long does an order take to print/ship?",
        a: `
          Typical turn around turn around is around 3-5 days depending on the model scale, number of buildings, and design work needed.
        `,
      },
      {
        q: "Can you add a feature to the site model makerer for me?",
        a: `
          The Site Model Maker is in active development and we would love to hear your suggestions.
          Feel free to reach out to us at contact@titanic.design.
          We will gladly try and add the feature or do some custom modeling to make your model a reality.
        `,
      },
    ],
  }
]

class FAQ extends React.Component<{}, {}> {

  render() {
    return (
      <Layout>
        <SEO title="FAQ"/>
        <div className="jumbotron" style={{backgroundColor: "#FAFFFF", marginBottom: 0, minHeight: "100vh"}}>
          <div className="container">
            <h2 className="mb-5">Frequently Asked Questions</h2>
            {faqData.map((section, index) => (
              <div key={index}>
                 {/* <h4 className="h4">{section.title}</h4> */}
                 {section.questions.map((question, index) => (
                    <details key={index} className="mb-4">
                      <summary className="lead my-3">
                        {question.q}
                      </summary>
                      <div style={{fontSize: "15px", color: "#212121"}}>{question.a}</div>
                    </details>
                  ))}
              </div>
            ))}
            <h4 className="mt-5">Still Have a Question?</h4>
            <p className="mt-2" style={{fontSize: "16px"}}>
              Feel free to send us an email at contact@titanic.design or <br/>
              pick up the phone and give us a call at (801) 815-2987 <br/>
            </p>
          </div>
        </div>
      </Layout>
    );
  }
}

export default FAQ
